.banner-container {
    margin:1.5rem 0px 3rem;
    background-color: #009cff;
    color:#daddc4;
}
.link {
    padding:12px;
    border: none;
}
.link a {
    color:rgb(221, 226, 225);
    text-decoration: none;
}