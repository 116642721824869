.document-editor-container {
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 30px;
    background-color: #f0f4f8;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-size: 28px;
    font-weight: bold;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .document-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .document-action .buttons {
    display: flex;
    gap: 10px;
  }
  
  button {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button .icon {
    margin-right: 8px;
  }
  
  input[type="file"] {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 50%;
  }
  
  @media (max-width: 768px) {
    .document-action {
      flex-direction: column;
      align-items: flex-start;
    }
  
    button {
      margin-bottom: 10px;
      width: 100%;
    }
  
    input[type="file"] {
      width: 100%;
    }
  }
  