@import url("https://cdn-uicons.flaticon.com/2.2.0/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url("https://cdn-uicons.flaticon.com/2.2.0/uicons-regular-straight/css/uicons-regular-straight.css");
.search {
  width: 500px !important;
}
.header-item-style {
  text-decoration: none;
  color: #daddc4;
}
.username-style {
  color: #daddc4;
}
.header-item-style:hover {
  color: rgb(0, 0, 0) !important;
}
.soil-logo {
  color: rgb(218, 221, 196);
  text-decoration: none !important;
}

.header-nav {
  background-color: #3d33ff !important;
}
.items-cart {
  background-color: rgb(255, 29, 29);
  color: white;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  padding: 0px 5px;
  position: relative;
  left: -45px;
  top: -15px;

  opacity: 0.9;
}
